/* PortfolioSelect.css */

ul.portfolio-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: space-around;
  gap: 10px; /* Add gap between items */
}

li.portfolio-item {
  cursor: pointer;
  list-style-type: none;
  margin: 3px;
  width: 23%; /* Ensure 4 items fit in a row, accounting for margins and gaps */
  height: 600px; /* Adjust height to accommodate larger image */
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease; /* Add transition for smooth animation */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white; /* Ensure text is white for contrast */
}

img.portfolio-image {
  width: 100%;
  height: 80%; /* Maintain aspect ratio by ensuring height fills most of the container */
  object-fit: cover; /* Cover ensures the image covers the container without stretching */
  border-radius: 10px;
  transition: transform 0.4s ease; /* Add transition for smooth animation */
  position: absolute; /* Position absolute to allow scaling */
  top: 0;
  left: 0;
}

li.portfolio-item:hover img.portfolio-image {
  transform: scale(1.20); /* Slightly enlarge image on hover */
}

.portfolio-title {
  margin-top: 8px;
  font-size: 1.75rem; /* Set the font size to 2.5rem */
  text-align: center;
  color: white; /* Ensure the title is white */
  position: absolute;
  bottom: 20px; /* Position at the bottom of the container */
  width: 100%; /* Full width to ensure centering */
}


