@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@700&display=swap'); /* Example font */

.font-fancy {
  font-family: 'Merriweather';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-black {
  background-color: #000;
}
.dropdown-content {
  top: 100%;
  left: 0;
  z-index: 10;
}

